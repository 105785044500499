<template>
  <b-container>
    <b-row>
      <b-col
        v-for="item in $store.state.certificates"
        :key="item.name"
        @click="
          $router.replace({
            name: $route.name,
            query: {
              ...$route.query,
              modalType: 'modalCertificate',
              id: item.id,
            },
          })
        "
        md="4"
        class="mt-3"
      >
        <b-card :header="item.name">
          <img
            class="card-img-top cursor"
            :src="item.url"
            :alt="item.description"
        /></b-card>
      </b-col>
    </b-row>
    <cerificate-modal />
  </b-container>
</template>

<script>
import CerificateModal from "../components/CerificateModal.vue";
export default {
  components: { CerificateModal },
  async beforeMount() {
    await this.$store.dispatch("setCertificates");
    this.showModal();
  },
  watch: {
    $route() {
      this.showModal();
    },
  },
  methods: {
    showModal() {
      if (this.$route.query.modalType === "modalCertificate")
        this.$bvModal.show("modalCertificate");
    },
  },
};
</script>

<style>
</style>