<template>
  <b-modal
    id="modalCertificate"
    ok-only
    @hidden="closeModal()"
    centered
    size="lg"
    :title="item.title"
    ><img class="img-fluid" :src="item.url"
  /></b-modal>
</template>

<script>
export default {
  computed: {
    item() {
      return (
        this.$store.state.certificates[
          this.$store.state.certificates.findIndex(
            (v) => v.id == this.$route.query.id
          )
        ] || {}
      );
    },
  },
  methods: {
    closeModal() {
      var q = { ...this.$route.query };
      this.$delete(q, "id");
      this.$delete(q, "modalType");
      this.$router.replace({
        name: this.$route.name,
        query: q,
      });
    },
  },
};
</script>

<style>
</style>