var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',[_c('b-row',_vm._l((_vm.$store.state.certificates),function(item){return _c('b-col',{key:item.name,staticClass:"mt-3",attrs:{"md":"4"},on:{"click":function($event){return _vm.$router.replace({
          name: _vm.$route.name,
          query: {
            ..._vm.$route.query,
            modalType: 'modalCertificate',
            id: item.id,
          },
        })}}},[_c('b-card',{attrs:{"header":item.name}},[_c('img',{staticClass:"card-img-top cursor",attrs:{"src":item.url,"alt":item.description}})])],1)}),1),_c('cerificate-modal')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }